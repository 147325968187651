import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import styled from 'styled-components';
import { Helmet } from "react-helmet";

const Div = styled.div`
  min-height: 85vh;
  display: flex;
  align-items: center;
  
  span { color: var(--blueDress) }

  button {
    background-color: var(--blueDress);
    color: white;
    border: none;
    padding: 0.75em 4.5em;
    border-radius: 50px;
  }

  .col-5 > *+* {
    margin-top: 2rem;
  }
`;

// markup
const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>404 | Niro</title>
      </Helmet>
      <Layout>
        <Div>
          <div className='container'>
            <div className='row text-center'>
              <div className='col-5 mx-auto d-flex flex-column align-items-center'>
                <h1>Error <span>404</span></h1>
                <h4>Sorry we couldn't find what you were looking for.</h4>
                <Link to='/'>
                  <button>Back to Home</button>
                </Link>
              </div>
            </div>
          </div>
        </Div>
      </Layout>
    </>
  )
}

export default NotFoundPage
